export const Footer = () => {
  return (
    <footer>
      <div id='footer' className='footer-container'>
        <div className='container text-center'>
          <p>
            &copy; 2023 R.P.A Painting Solution. Design by{" "}
            <a href='http://www.empresasoluciones.com' target='_blank' rel='noopener noreferrer'>
              &nbsp; EmpresaSoluciones
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};
