import { useEffect, useState } from "react";
import logo from "../assets/logo.png";

export const Navigation = ({ theme, setTheme }) => {
  const [navBarBackground, setNavBarBackground] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavBarBackground(true);
    } else {
      setNavBarBackground(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  return (
    <header>
      <nav id='menu' className={`navbar  navbar-fixed-top active-${navBarBackground}`}>
        <div className='container-fluid flex'>
          <div className='navbar-header flex'>
            <a className='flex-start m-0' href='#page-top'>
              <img src={logo} alt='logo RPA Painting Solution' width='15%' loading='lazy' />
              <h2 style={{ fontSize: "2rem", margin: 0 }}>R.P.A Painting solution</h2>
            </a>

            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
            >
              <span className='sr-only'>Toggle navigation</span>
              <i className='fa-solid fa-bars'></i>
            </button>
          </div>

          <div
            className='container'
            style={{ display: "flex", justifyContent: "flex-end", marginRight: "5%" }}
          >
            <div className='collapse navbar-collapse' id='bs-example-navbar-collapse-1'>
              <ul className='nav navbar-nav navbar-right'>
                <li>
                  <a href='#features' className='page-scroll'>
                    Features
                  </a>
                </li>
                <li>
                  <a href='#about' className='page-scroll'>
                    About
                  </a>
                </li>
                <li>
                  <a href='#services' className='page-scroll'>
                    Services
                  </a>
                </li>
                <li>
                  <a href='#gallery' className='page-scroll'>
                    Gallery
                  </a>
                </li>
                <li>
                  <a href='#testimonials' className='page-scroll'>
                    Testimonials
                  </a>
                </li>
                <li>
                  <div
                    className='page-scroll'
                    style={{
                      cursor: "pointer",
                      paddingLeft: "50%",
                      paddingTop: "19px",
                      paddingBottom: "15px",
                    }}
                  >
                    <div onClick={() => setTheme(theme === "dark" ? "white" : "dark")}>
                      {theme === "dark" ? (
                        <i className='fa fa-moon-o' style={{ color: "white" }}></i>
                      ) : (
                        <i className='fa fa-sun-o' style={{ color: "black" }}></i>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
