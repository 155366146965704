import React from 'react';

const SpeedDial = () => {
  function callPhone() {
    window.open('tel:+13059240685');
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
      }}
    >
      <button
        onClick={callPhone}
        style={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          backgroundColor: '#ff329f',
          color: 'white',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
        }}
      >
        <i class='fa-solid fa-phone'></i>
      </button>
    </div>
  );
};

export default SpeedDial;
