import React from 'react';

import videoBg from '../assets/videos/paintingVideo.mp4';

export const Welcome = (props) => {
  return (
    <header id='welcome'>
      <div className='intro'>
        <video autoPlay loop muted preload='metadata'>
          <source src={videoBg} type='video/mp4' />
        </video>

        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1 className='animate__animated animate__fadeInDown'>
                  {props.data ? props.data.title : 'Loading'}
                </h1>

                <p className='fade-in-2'>{props.data ? props.data.paragraph : 'Loading'}</p>

                <a
                  href='https://api.whatsapp.com/send?phone=13059240685'
                  target='_blank'
                  className='btn btn-custom btn-lg page-scroll fade-in-2'
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
