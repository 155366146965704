import React from "react";

function splashLoading() {
  return (
    <div className='container-fluid flex'>
      <div class='loader'></div>;
    </div>
  );
}

export default splashLoading;
