import React, { Suspense, useEffect, useState } from 'react';
import { Footer } from '../../components/footer';
import { Welcome } from '../../components/Welcome';
import { Navigation } from '../../components/navigation';
import JsonData from '../../data/data.json';
import SplashLoading from '../../components/elements/splashLoading';
import SpeedDial from '../../components/elements/speedDial';

function Home({ setTheme, theme }) {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const Features = React.lazy(() => import('../../components/features'));
  const About = React.lazy(() => import('../../components/about'));
  const Services = React.lazy(() => import('../../components/services'));
  const Gallery = React.lazy(() => import('../../components/gallery'));
  const Testimonials = React.lazy(() => import('../../components/testimonials'));
  const Contact = React.lazy(() => import('../../components/contact'));

  return (
    <div>
      <Navigation theme={theme} setTheme={setTheme} />

      <main>
        <Suspense fallback={<span>Loading...</span>}>
          <SpeedDial />
          <Welcome data={landingPageData.Header} />
          <Features data={landingPageData.Features} />
          <About data={landingPageData.About} />
          <Services data={landingPageData.Services} />
          <Gallery />
          <Testimonials data={landingPageData.Testimonials} />
          <Contact data={landingPageData.Contact} />
        </Suspense>
      </main>

      <Footer />
    </div>
  );
}

export default Home;
